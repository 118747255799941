export const questionList = [
    {
        id: 0,
        question: '素材可以商用吗？',
        answer: '<p>PlayList全站资源（图片、音乐、音效、视频、AI语音）可以商用，拥有正规版权。会员用户下载素材后按需求激活授权书即可商用。</p>'
    },
    {
        id: 1,
        question: '素材多久更新一次？',
        answer: '<p>PlayList素材每日更新，全站资源数量每周更新500+。</p>'
    },
    {
        id: 2,
        question: '会员到期后素材还可以使用吗？',
        answer: '<p>① 会员到期前，下载的素材生成作品并且激活授权的，该授权作品可以永久投放（投放渠道除公播之外的作品，公播素材的有效期为会员有效期）。会员到期后，已经下载的素材不能继续使用；</p><p>② 需在作品投放及会员到期前激活授权书，没有激活授权书的素材不可使用，在作品投放及会员到期后再激活授权书，该授权书无效。</p>'
    },
    {
        id: 3,
        question: '个人会员跟企业会员有什么区别？',
        answer: '<p>个人会员下载的素材只可用作私人项目使用，企业会员下载的素材可用作企业项目。详细权益可前往【开通会员】页面【订阅权益】中查看。</p>'
    },
    {
        id: 4,
        question: '开通会员后下载素材还需要额外收费吗？',
        answer: '<p>不需要。开通会员后您可使用会员内提供的下载次数。如您的下载次数不够用，可升级会员，或者联系客服开通定制化服务。</p>'
    },
    {
        id: 5,
        question: '开通会员后所有素材都可以使用吗？',
        answer: '<p>是的。开通会员后，PlayList全站资源（图片、音乐、音效、视频、AI语音），均可下载使用。 </p>'
    },
    {
        id: 6,
        question: '有合同吗？怎么申请？',
        answer: '<p>① 付费后，鼠标悬浮在网站右上角头像上，点击【我的会员】； </p><p> ② 在【我的会员】页面，对应会员列表后方选择【查看详情】；</p><p> ③ 会员详情页面，点击【订阅服务协议】，输入您的合同信息，即可下载订阅服务协议（合同）。</p>'
    },
    {
      // <label>注：需要先下载订阅服务协议，再激活授权书。</label>
        id: 7,
        question: '下载素材后怎么激活授权书？',
        answer: '<p>下载订阅服务协议后，在【授权下载】页面找到需要激活授权书的素材，点击【激活授权书】按钮，填写授权信息，即可激活授权书。</p>'
    },
    {
        id: 8,
        question: '素材可以多个平台投放吗？',
        answer: '<p>可以。在激活授权书时，可选择多个投放渠道，为避免版权权益风险请选择正常投放渠道信息。</p>'
    },
    {
        id: 9,
        question: '下载的素材在本地找不到？',
        answer: '<p>查看您的浏览器默认下载内容存储路径。谷歌浏览器为例：</p><p>① 点击浏览器右上角3个点图标；<br> ② 点击【下载内容】； <br>③ 显示下载内容列表，点击【在文件夹中显示】，即可找到下载的素材。</p><p>设置浏览器默认下载路径：</p><p>① 点击浏览器右上角3个点图标；<br> ② 点击【设置】；<br> ③ 点击左侧【下载内容】；<br> ④ 点击【更改】，选择文件下载路径后，后续下载的内容会到您选择的默认路径下。<br></p>'
    },
    {
        id: 10,
        question: '授权项目名称怎么填写？',
        answer: '<p>激活授权书时，选择投放渠道后，填写授权项目，填写您投放渠道作品的项目名称（作品名称）。</p>'
    },
    {
        id: 11,
        question: '为什么下载被拦截？',
        answer: '<p>电脑浏览器为了保证用户安全，拦截下载操作。可手动设置以谷歌浏览器为例：</p><p>① 点击锁图标（地址栏网站链接前面）；<br> ② 在弹窗选择【网站设置】；<br> ③ 把以下两个权限项都调整为允许【弹出式窗口和重定向】【自动下载项】；<br> ④ 回到Playlist网站提示更新后的应用到此网站，点击 重新加载即可。</p>'
    },
    {
        id: 12,
        question: '怎么申请发票？',
        answer: '<p>网站右上角鼠标悬浮到头像处，点击【发票管理】，在列表处选择您需要开具的发票，输入开票信息即可。电子普通发票不限金额，增值税专用发票需金额大于1000元可开具。</p>'
    },
]
