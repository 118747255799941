<template>
    <div class="container">
        <!-- 面包屑 -->
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><div @click="back"><el-icon name="arrow-left"/>返回</div></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="question_cont">
            <p class="title"><span>常见问题</span>（温馨提示：使用<label>谷歌浏览器</label>访问PlayList，试听体验效果最佳）</p>
            <el-collapse>
                <el-collapse-item  v-for="(item,index) in questionList" :key="index" :name="index+1">
                    <template slot="title">
                        <span>Q</span>{{ item.question }}
                    </template>
                    <div v-html="item.answer"></div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
import { questionList } from '@/config/questions'
export default {
    data(){
        return{
            questionList,
            // activeNames: ['1']
        }
    },
    methods: {
      back(){
        this.$router.go(-1)
      }
    },
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    padding-top: 100px;
    .breadcrumb{
        padding-left: 120px;
        /deep/.el-breadcrumb__item{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.6);
            .el-breadcrumb__inner{
                cursor: pointer;
            }
        }
    }
    .question_cont{
        max-width: 1200px;
        margin: 20px auto 97px;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(33,48,83,0.05);
        border-radius: 8px;
        padding: 0px 40px 100px 40px;
        .title{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.5);
            padding: 20px 0px;
            letter-spacing: 1px;
            border-bottom: 2px solid rgba(153, 153, 153, 0.3);;
            span{
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            label{
                color: #0061F3;
                font-weight: 500;
            }
        }
        /deep/.el-collapse{
            border-top: none!important;
        }
        /deep/.el-collapse-item{
            .el-collapse-item__header{
                height: 68px!important;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 0.8);
                padding-left: 10px;
                span{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 20px;
                    text-align: center;
                    background: rgba(51, 51, 51, 0.8);
                    border-radius: 3px;
                    margin-right: 10px;
                }
                .el-collapse-item__arrow{
                    display: block!important;
                }
            }
            .el-collapse-item__wrap{
                border-bottom: 1px dashed rgba(153, 153, 153, 0.3)!important;
                .el-collapse-item__content{
                    padding-left: 40px;
                    p{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 0.7);
                        line-height: 28px;
                    }
                    label{
                        color: #0061F3;
                    }
                }
            }
        }
    }
}
</style>
